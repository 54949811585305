var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('b-form',{attrs:{"id":"security-form","name":"security-form","autocomplete":"off","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitSecurityForm.apply(null, arguments)}}},[_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":"7","md":"5","lg":"3"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("HttpClientType")]),_c('div',{staticClass:"floating-select-field"},[_c('b-form-select',{staticClass:"form-control",class:{
                'is-value-exist': _vm.securityDetails.httpClientType != null,
              },attrs:{"id":"http-client-type","options":_vm.httpClientTypeOptions,"value-field":"value","text-field":"title","required":""},on:{"input":function($event){_vm.securityDetails.httpClientType = $event}},model:{value:(_vm.securityDetails.httpClientType),callback:function ($$v) {_vm.$set(_vm.securityDetails, "httpClientType", $$v)},expression:"securityDetails.httpClientType"}}),_c('label',{attrs:{"for":"http-client-type"}},[_vm._v("HttpClientType")]),(!_vm.securityDetails.httpClientType && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("HttpClientType required.")]):_vm._e()],1)])],1)],1),(_vm.securityDetails.httpClientType == 'simpleAuthentication')?[_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("API key name")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{attrs:{"id":"api-key-name","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityDetails.simpleAuthentication.apiKeyName),callback:function ($$v) {_vm.$set(_vm.securityDetails.simpleAuthentication, "apiKeyName", $$v)},expression:"securityDetails.simpleAuthentication.apiKeyName"}}),_c('label',{attrs:{"for":"api-key-name"}},[_vm._v("API key name")]),(
                  !_vm.securityDetails.simpleAuthentication.apiKeyName &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("API key name required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("API key value")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{attrs:{"id":"api-key-value","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityDetails.simpleAuthentication.apiKeyValue),callback:function ($$v) {_vm.$set(_vm.securityDetails.simpleAuthentication, "apiKeyValue", $$v)},expression:"securityDetails.simpleAuthentication.apiKeyValue"}}),_c('label',{attrs:{"for":"api-key-value"}},[_vm._v("API key value")]),(
                  !_vm.securityDetails.simpleAuthentication.apiKeyValue &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("API key value required.")]):_vm._e()],1)])],1)],1)]:_vm._e(),(_vm.securityDetails.httpClientType == 'oauth2Authentication')?[_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Authentication URL")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{attrs:{"id":"authentication-url","type":"url","placeholder":" ","required":""},model:{value:(
                  _vm.securityDetails.oauth2Authentication.authenticationUrl
                ),callback:function ($$v) {_vm.$set(_vm.securityDetails.oauth2Authentication, "authenticationUrl", $$v)},expression:"\n                  securityDetails.oauth2Authentication.authenticationUrl\n                "}}),_c('label',{attrs:{"for":"authentication-url"}},[_vm._v("Authentication URL")]),(
                  !_vm.securityDetails.oauth2Authentication.authenticationUrl &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Authentication URL required.")]):_vm._e(),(
                  _vm.securityDetails.oauth2Authentication.authenticationUrl &&
                  !_vm.validateUrl(
                    _vm.securityDetails.oauth2Authentication.authenticationUrl
                  ) &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Invalid url.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Client ID")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{attrs:{"id":"client-id","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityDetails.oauth2Authentication.clientId),callback:function ($$v) {_vm.$set(_vm.securityDetails.oauth2Authentication, "clientId", $$v)},expression:"securityDetails.oauth2Authentication.clientId"}}),_c('label',{attrs:{"for":"client-id"}},[_vm._v("Client ID")]),(
                  !_vm.securityDetails.oauth2Authentication.clientId &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Client ID required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Client secret")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{attrs:{"id":"client-secret","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityDetails.oauth2Authentication.clientSecret),callback:function ($$v) {_vm.$set(_vm.securityDetails.oauth2Authentication, "clientSecret", $$v)},expression:"securityDetails.oauth2Authentication.clientSecret"}}),_c('label',{attrs:{"for":"client-secret"}},[_vm._v("Client secret")]),(
                  !_vm.securityDetails.oauth2Authentication.clientSecret &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Client secret required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Scope")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{attrs:{"id":"scope","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityDetails.oauth2Authentication.scope),callback:function ($$v) {_vm.$set(_vm.securityDetails.oauth2Authentication, "scope", $$v)},expression:"securityDetails.oauth2Authentication.scope"}}),_c('label',{attrs:{"for":"scope"}},[_vm._v("Scope")]),(
                  !_vm.securityDetails.oauth2Authentication.scope && _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Scope required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Grant type")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{attrs:{"id":"grant-type","type":"text","placeholder":" ","readonly":"","required":""},model:{value:(_vm.securityDetails.oauth2Authentication.grantType),callback:function ($$v) {_vm.$set(_vm.securityDetails.oauth2Authentication, "grantType", $$v)},expression:"securityDetails.oauth2Authentication.grantType"}}),_c('label',{attrs:{"for":"grant-type"}},[_vm._v("Grant type")]),(
                  !_vm.securityDetails.oauth2Authentication.grantType &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Grant type required.")]):_vm._e()],1)])],1)],1)]:_vm._e(),_c('b-form-row',{staticClass:"my-3"},[_c('b-col',[_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],attrs:{"type":"submit","variant":"primary","disabled":_vm.saveLoadingIcon}},[_vm._v("Save "),(_vm.saveLoadingIcon)?_c('b-spinner',{staticClass:"ml-2",attrs:{"label":"Spinning","small":""}}):_vm._e()],1),_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],staticClass:"ml-3",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.resetSecurityForm}},[_vm._v("Cancel")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
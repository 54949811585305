<template>
  <section>
    <b-form
      id="security-form"
      name="security-form"
      autocomplete="off"
      @submit.prevent="onSubmitSecurityForm"
      novalidate
    >
      <b-form-row>
        <b-col cols="12" sm="7" md="5" lg="3">
          <b-form-group>
            <h4 class="page-sub-header">HttpClientType</h4>
            <div class="floating-select-field">
              <b-form-select
                id="http-client-type"
                class="form-control"
                :options="httpClientTypeOptions"
                value-field="value"
                text-field="title"
                v-model="securityDetails.httpClientType"
                @input="securityDetails.httpClientType = $event"
                required
                :class="{
                  'is-value-exist': securityDetails.httpClientType != null,
                }"
              >
              </b-form-select>
              <label for="http-client-type">HttpClientType</label>
              <b-form-invalid-feedback
                class="d-block"
                v-if="!securityDetails.httpClientType && formSubmitted"
                >HttpClientType required.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <template v-if="securityDetails.httpClientType == 'simpleAuthentication'">
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">API key name</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="api-key-name"
                  type="text"
                  placeholder=" "
                  v-model="securityDetails.simpleAuthentication.apiKeyName"
                  required
                ></b-form-input>
                <label for="api-key-name">API key name</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !securityDetails.simpleAuthentication.apiKeyName &&
                    formSubmitted
                  "
                  >API key name required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">API key value</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="api-key-value"
                  type="text"
                  placeholder=" "
                  v-model="securityDetails.simpleAuthentication.apiKeyValue"
                  required
                ></b-form-input>
                <label for="api-key-value">API key value</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !securityDetails.simpleAuthentication.apiKeyValue &&
                    formSubmitted
                  "
                  >API key value required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
      </template>
      <template v-if="securityDetails.httpClientType == 'oauth2Authentication'">
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Authentication URL</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="authentication-url"
                  type="url"
                  placeholder=" "
                  v-model="
                    securityDetails.oauth2Authentication.authenticationUrl
                  "
                  required
                ></b-form-input>
                <label for="authentication-url">Authentication URL</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !securityDetails.oauth2Authentication.authenticationUrl &&
                    formSubmitted
                  "
                  >Authentication URL required.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    securityDetails.oauth2Authentication.authenticationUrl &&
                    !validateUrl(
                      securityDetails.oauth2Authentication.authenticationUrl
                    ) &&
                    formSubmitted
                  "
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Client ID</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="client-id"
                  type="text"
                  placeholder=" "
                  v-model="securityDetails.oauth2Authentication.clientId"
                  required
                ></b-form-input>
                <label for="client-id">Client ID</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !securityDetails.oauth2Authentication.clientId &&
                    formSubmitted
                  "
                  >Client ID required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Client secret</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="client-secret"
                  type="text"
                  placeholder=" "
                  v-model="securityDetails.oauth2Authentication.clientSecret"
                  required
                ></b-form-input>
                <label for="client-secret">Client secret</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !securityDetails.oauth2Authentication.clientSecret &&
                    formSubmitted
                  "
                  >Client secret required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Scope</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="scope"
                  type="text"
                  placeholder=" "
                  v-model="securityDetails.oauth2Authentication.scope"
                  required
                ></b-form-input>
                <label for="scope">Scope</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !securityDetails.oauth2Authentication.scope && formSubmitted
                  "
                  >Scope required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Grant type</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="grant-type"
                  type="text"
                  placeholder=" "
                  readonly
                  v-model="securityDetails.oauth2Authentication.grantType"
                  required
                ></b-form-input>
                <label for="grant-type">Grant type</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !securityDetails.oauth2Authentication.grantType &&
                    formSubmitted
                  "
                  >Grant type required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
      </template>
      <b-form-row class="my-3">
        <b-col>
          <b-button
            type="submit"
            v-activeBlur
            variant="primary"
            :disabled="saveLoadingIcon"
            >Save
            <b-spinner
              v-if="saveLoadingIcon"
              label="Spinning"
              small
              class="ml-2"
            ></b-spinner
          ></b-button>
          <b-button
            type="button"
            v-activeBlur
            class="ml-3"
            @click="resetSecurityForm"
            variant="outline-secondary"
            >Cancel</b-button
          >
        </b-col>
      </b-form-row>
    </b-form>
  </section>
</template>
<script>
import { cloneDeep } from 'lodash'
import { useValidateFields } from '@/composables/useValidateFields'
export default {
  name: 'Security',
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()

    return { validateUrl, scrollToErrorMessage }
  },
  data () {
    return {
      formSubmitted: false,
      saveLoadingIcon: false,
      httpClientTypeOptions: [
        {
          title: 'Default',
          value: 'default'
        },
        {
          title: 'Simple Authentication',
          value: 'simpleAuthentication'
        },
        {
          title: 'OAuth2 Authentication',
          value: 'oauth2Authentication'
        }
      ],
      cloneSecurityDetails: null,
      securityDetails: {
        httpClientType: 'default',
        default: {},
        simpleAuthentication: {
          apiKeyName: null,
          apiKeyValue: null
        },
        oauth2Authentication: {
          authenticationUrl: null,
          clientId: null,
          clientSecret: null,
          scope: null,
          grantType: 'client_credentials'
        }
      }
    }
  },
  mounted () {
    this.getSecurityDetails()
  },
  methods: {
    showToaster (message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type
      })
    },
    getSecurityDetails () {
      this.$store
        .dispatch(
          'cities/getAPICommunicationConfiguration',
          this.$route.params.tenantID
        )
        .then((response) => {
          if (response.data) {
            this.securityDetails.httpClientType = response.data.httpClientType
            if (response.data.simpleAuthentication) {
              this.securityDetails.simpleAuthentication =
                response.data.simpleAuthentication
            }
            if (response.data.oauth2Authentication) {
              this.securityDetails.oauth2Authentication =
                response.data.oauth2Authentication
            }
            if (this.securityDetails.httpClientType === 'oauth2Authentication') {
              this.resetSimpleAuth()
            } else if (
              this.securityDetails.httpClientType === 'simpleAuthentication'
            ) {
              this.resetO2Auth()
            } else {
              this.resetSimpleAuth()
              this.resetO2Auth()
            }
            this.cloneSecurityDetails = cloneDeep(this.securityDetails)
          }
        })
        .catch(() => {
          this.resetSecurity()
          this.showToaster(false, 'ERROR_GET_API_COMMU_CONFIG', 'danger')
        })
    },
    resetSimpleAuth () {
      for (const key in this.securityDetails.simpleAuthentication) {
        this.securityDetails.simpleAuthentication[key] = null
      }
    },
    resetO2Auth () {
      for (const key in this.securityDetails.oauth2Authentication) {
        this.securityDetails.oauth2Authentication[key] =
          key === 'grantType' ? 'client_credentials' : null
      }
    },
    resetSecurityForm () {
      this.securityDetails = cloneDeep(this.cloneSecurityDetails)
    },
    validateSecurityForm () {
      if (this.securityDetails.httpClientType === 'default') {
        return true
      } else if (
        this.securityDetails.httpClientType === 'simpleAuthentication'
      ) {
        return !Object.values(this.securityDetails.simpleAuthentication).some(
          (v) => !v
        )
      } else if (
        this.securityDetails.httpClientType === 'oauth2Authentication'
      ) {
        return (
          this.validateUrl(
            this.securityDetails.oauth2Authentication.authenticationUrl
          ) &&
          !Object.values(this.securityDetails.oauth2Authentication).some(
            (v) => !v
          )
        )
      }
      return false
    },
    onSubmitSecurityForm () {
      this.formSubmitted = true
      this.scrollToErrorMessage()
      if (this.validateSecurityForm()) {
        this.saveLoadingIcon = true
        const postObj = {
          httpClientType: this.securityDetails.httpClientType,
          default: this.securityDetails.httpClientType === 'default' ? {} : null,
          simpleAuthentication:
            this.securityDetails.httpClientType === 'simpleAuthentication'
              ? this.securityDetails.simpleAuthentication
              : null,
          oauth2Authentication:
            this.securityDetails.httpClientType === 'oauth2Authentication'
              ? this.securityDetails.oauth2Authentication
              : null
        }
        this.$store
          .dispatch('cities/updateAPICommunicationConfiguration', {
            tenantID: this.$route.params.tenantID,
            data: postObj
          })
          .then((response) => {
            this.saveLoadingIcon = false
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
            } else if (response.data) {
              this.getSecurityDetails()
              this.formSubmitted = false
              this.showToaster(false, 'UPDATE_API_COMMU_CONFIG', 'success')
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
          })
          .catch(() => {
            this.saveLoadingIcon = false
            this.showToaster(false, 'FAILED', 'danger')
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
